<template>
  <div class="container">
    <div class="header card">
      <h1>
        <span
          v-lang="
            $route.query.pkFormVictimsId != null
              ? 'victims.edit_victim'
              : 'victims.add_victim'
          "
        ></span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <Loader2 v-if="!resultsLoaded" />

    <div class="card" v-else>
      <form
        class="form"
        v-on:submit.prevent="
          $route.query.pkFormVictimsId != null ? update() : save()
        "
        data-vv-scope="victim_form"
        v-responsive="{
          small: el => el.width < 985,
          large: el => el.width > 1300
        }"
      >
        <div class="general-data">
          <div
            class="input m-0"
            :class="
              errors.first('victim_form.number') !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="number">
              <span v-lang="'victims.number'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <input
              class="m-0"
              v-model="model.number"
              type="text"
              name="number"
              autocomplete="off"
              v-validate="'required'"
            />
            <div>
              <span class="error" v-html="errors.first('victim_form.number')"></span>
            </div>
          </div>
          <div
            class="input m-0"
            :class="
              errors.first('victim_form.fkInstitutionId') !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="fkInstitutionId">
              <span v-lang="'victims.institution'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <select
              v-model="model.fkInstitutionId"
              v-validate="'required|excluded:0'"
              name="fkInstitutionId"
              id="dropdownStatusCode"
              :disabled="disabledInstitution"
            >
              <option value="0" selected v-lang="'victims.placeholder_for_institution'"></option>
              <option
                v-for="(obj, key) in codesModel.institutionCodes"
                :key="key"
                :value="obj.id"
              >{{ obj.name }}</option>
            </select>
            <div>
              <span class="error" v-html="errors.first('victim_form.fkInstitutionId')"></span>
            </div>
          </div>
          <div
            class="input m-0"
            :class="
              errors.first('victim_form.fkInstitutionTypeCodeId') !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="fkInstitutionTypeCodeId">
              <span v-lang="'victims.institution_type'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <select
              v-model="model.fkInstitutionTypeCodeId"
              v-validate="'required|excluded:0'"
              name="fkInstitutionTypeCodeId"
              id="dropdownStatusCode"
            >
              <option value="0" selected v-lang="'victims.placeholder_for_institution_type'"></option>
              <option
                v-for="(obj, key) in codesModel.institutionTypeCodes"
                :key="key"
                :value="obj.pkCodeId"
              >{{ obj.name }}</option>
            </select>
            <div>
              <span class="error" v-html="errors.first('victim_form.fkInstitutionTypeCodeId')"></span>
            </div>
          </div>
          <div
            class="input m-0"
            :class="
              errors.first('victim_form.registrationDate') !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="date">
              <span v-lang="'victims.registration_date'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <date-picker
              name="registrationDate"
              v-model="model.registrationDate"
              :inputClass="'input-date-picker'"
              :locale="lang"
              :initDate="false"
            ></date-picker>
            <div>
              <span class="error" v-html="errors.first('victim_form.registrationDate')"></span>
            </div>
          </div>
        </div>
        <fieldset class="fieldset">
          <legend v-lang="'victims.person_data'"></legend>
          <div
            class="fieldset-main-container"
            v-responsive="{
              small: el => el.width < 985,
              large: el => el.width > 1300
            }"
          >
            <div
              class="input m-0"
              :class="
                errors.first('victim_form.fkGenderCodeId') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="fkGenderCodeId">
                <span v-lang="'victims.gender'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <select
                v-model="model.fkGenderCodeId"
                v-validate="'required|excluded:0'"
                name="fkGenderCodeId"
                id="dropdownStatusCode"
              >
                <option value="0" selected v-lang="'victims.placeholder_for_gender'"></option>
                <option
                  v-for="(obj, key) in codesModel.genderCodes"
                  :key="key"
                  :value="obj.pkCodeId"
                >{{ obj.name }}</option>
              </select>
              <div>
                <span class="error" v-html="errors.first('victim_form.fkGenderCodeId')"></span>
              </div>
            </div>
            <div
              class="input m-0"
              :class="
                errors.first('victim_form.fkYearsCodeId') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="fkYearsCodeId">
                <span v-lang="'victims.years'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <select
                v-model="model.fkYearsCodeId"
                v-validate="'required|excluded:0'"
                name="fkYearsCodeId"
                id="dropdownStatusCode"
              >
                <option value="0" selected v-lang="'victims.placeholder_for_years'"></option>
                <option
                  v-for="(obj, key) in codesModel.yearCodes"
                  :key="key"
                  :value="obj.pkCodeId"
                  :disabled="
                    model.isMinor &&
                      (obj.pkCodeId == 2776 || obj.pkCodeId == 2777)
                  "
                >{{ obj.name }}</option>
              </select>
              <div>
                <span class="error" v-html="errors.first('victim_form.fkYearsCodeId')"></span>
              </div>
            </div>
            <div
              class="input m-0"
              :class="
                errors.first('victim_form.fkCitizenshipCodeId') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="fkCitizenshipCodeId">
                <span v-lang="'victims.citizenship'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <select
                v-model="model.fkCitizenshipCodeId"
                v-validate="'required|excluded:0'"
                name="fkCitizenshipCodeId"
                id="dropdownStatusCode"
              >
                <option value="0" selected v-lang="'victims.placeholder_for_citizenship'"></option>
                <option
                  v-for="(obj, key) in codesModel.countryCodes"
                  :key="key"
                  :value="obj.pkCodeId"
                >{{ obj.name }}</option>
              </select>
              <div>
                <span class="error" v-html="errors.first('victim_form.fkCitizenshipCodeId')"></span>
              </div>
            </div>
            <div
              class="input m-0"
              :class="
                errors.first('victim_form.fkVictimTypeCodeId') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="fkVictimTypeCodeId">
                <span v-lang="'victims.victim_type'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <select
                v-model="model.fkVictimTypeCodeId"
                v-validate="'required|excluded:0'"
                name="fkVictimTypeCodeId"
                id="dropdownStatusCode"
              >
                <option value="0" selected v-lang="'victims.placeholder_for_victim_type'"></option>
                <option
                  v-for="(obj, key) in codesModel.victimTypeCodes"
                  :key="key"
                  :value="obj.pkCodeId"
                >{{ obj.name }}</option>
              </select>
              <div>
                <span class="error" v-html="errors.first('victim_form.fkVictimTypeCodeId')"></span>
              </div>
            </div>
            <div class="input m-0">
              <label for="name">
                <span v-lang="'victims.recruitment_country'"></span>
              </label>
              <select
                v-model="model.fkRecruitmentCountryCodeId"
                name="fkRecruitmentCountryCodeId"
                id="dropdownCitizenshipCode"
              >
                <option value="0" selected v-lang="'victims.placeholder_for_recruitment_country'"></option>
                <option
                  v-for="(obj, key) in codesModel.countryCodes"
                  :key="key"
                  :value="obj.pkCodeId"
                >{{ obj.name }}</option>
              </select>
            </div>
            <div class="input m-0">
              <label for="name">
                <span v-lang="'victims.destination_country'"></span>
              </label>
              <select
                v-model="model.fkDestinationCountryCodeId"
                name="fkDestinationCountryCodeId"
                id="dropdownCitizenshipCode"
              >
                <option value="0" selected v-lang="'victims.placeholder_for_destination_country'"></option>
                <option
                  v-for="(obj, key) in codesModel.countryCodes"
                  :key="key"
                  :value="obj.pkCodeId"
                >{{ obj.name }}</option>
              </select>
            </div>
          </div>
          <fieldset class="fieldset">
            <legend>{{ $lang("victims.minor") }}</legend>
            <div class="minor">
              <div class="input-checkbox">
                <label for="isMinor">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.isMinor"
                    id="isMinor"
                    autocomplete="off"
                    hidden
                  />
                  <span v-lang="'victims.minor'"></span>
                </label>
              </div>
              <div class="input-checkbox" v-if="model.isMinor == true">
                <label for="isMinorUnaccompanied">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.isMinorUnaccompanied"
                    id="isMinorUnaccompanied"
                    autocomplete="off"
                    hidden
                  />
                  <span v-lang="'victims.minor_unaccompanied'"></span>
                </label>
              </div>
              <div
                class="input m-0"
                :class="
                errors.first('victim_form.fkMinorYearsCodeId') !== undefined
                  ? 'input-error'
                  : ''
              "
                v-if="model.isMinor == true"
              >
                <label for="name">
                  <span v-lang="'victims.minor_years'"></span>

                  <span class="required-field-star">&#x2605;</span>
                </label>
                <select
                  v-model="model.fkMinorYearsCodeId"
                  name="fkMinorYearsCodeId"
                  id="dropdownCitizenshipCode"
                  v-validate="model.isMinor ? 'required|excluded:0' : ''"
                >
                  <option value="0" selected v-lang="'victims.placeholder_for_minor_years'"></option>
                  <option
                    v-for="(obj, key) in codesModel.minorYearCodes"
                    :key="key"
                    :value="obj.pkCodeId"
                  >{{ obj.name }}</option>
                </select>
                <div>
                  <span class="error" v-html="errors.first('victim_form.fkMinorYearsCodeId')"></span>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend>{{ $lang("victims.exploitation_mode") }}</legend>
            <div class="fieldset-container">
              <div
                v-for="(list, key) in codesModel.exploitationModeCodes"
                class="input-checkbox"
                :key="key"
              >
                <label
                  :for="list.pkCodeId"
                  :class="
                    (list.pkCodeId != 1466 &&
                      model.exploitationModes.includes(1466)) ||
                    (list.pkCodeId == 1466 &&
                      (model.exploitationModes.includes(1463) ||
                        model.exploitationModes.includes(1464) ||
                        model.exploitationModes.includes(1465)))
                      ? 'disabled'
                      : ''
                  "
                >
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.exploitationModes"
                    autocomplete="off"
                    :value="list.pkCodeId"
                    :id="list.pkCodeId"
                    :disabled="
                      (list.pkCodeId != 1466 &&
                        model.exploitationModes.includes(1466)) ||
                        (list.pkCodeId == 1466 &&
                          (model.exploitationModes.includes(1463) ||
                            model.exploitationModes.includes(1464) ||
                            model.exploitationModes.includes(1465)))
                    "
                    hidden
                  />
                  <span v-html="list.name"></span>
                </label>
              </div>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend>{{$lang('victims.help_received')}}</legend>
            <div class="input-checkbox">
              <label for="isHelpReceived">
                <input
                  class="m-0"
                  type="checkbox"
                  v-model="model.isHelpReceived"
                  id="isHelpReceived"
                  autocomplete="off"
                  hidden
                />
                <span v-lang="'victims.help_received'"></span>
              </label>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend>{{$lang('victims.рesidence_permit')}}</legend>
            <div class="input-checkbox">
              <label for="residencePermit">
                <input
                  class="m-0"
                  type="checkbox"
                  v-model="model.residencePermit"
                  id="residencePermit"
                  autocomplete="off"
                  hidden
                />
                <span v-lang="'victims.рesidence_permit'"></span>
              </label>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend>{{$lang('victims.humanitarian_stay')}}</legend>
            <div class="input-checkbox">
              <label for="isHumanitarianStay">
                <input
                  class="m-0"
                  type="checkbox"
                  v-model="model.isHumanitarianStay"
                  id="isHumanitarianStay"
                  autocomplete="off"
                  hidden
                />
                <span v-lang="'victims.humanitarian_stay'"></span>
              </label>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend>{{$lang('victims.reflection_period')}}</legend>
            <div class="input-checkbox">
              <label for="reflectionPeriod">
                <input
                  class="m-0"
                  type="checkbox"
                  v-model="model.reflectionPeriod"
                  id="reflectionPeriod"
                  autocomplete="off"
                  hidden
                />
                <span v-lang="'victims.reflection_period'"></span>
              </label>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend>{{$lang('victims.status_pretection')}}</legend>
            <div class="input-checkbox">
              <label for="internationalProtection">
                <input
                  class="m-0"
                  type="checkbox"
                  v-model="model.internationalProtection"
                  id="internationalProtection"
                  autocomplete="off"
                  hidden
                />
                <span v-lang="'victims.international_protection'"></span>
              </label>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend>{{$lang('victims.victim_cooperation')}}</legend>
            <div class="input-checkbox">
              <label for="cooperation">
                <input
                  class="m-0"
                  type="checkbox"
                  v-model="model.cooperation"
                  id="cooperation"
                  autocomplete="off"
                  hidden
                />
                <span v-lang="'victims.cooperation'"></span>
              </label>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend>{{$lang('victims.victim_testifies_in_court')}}</legend>
            <div class="input-checkbox">
              <label for="testifiesInCourt">
                <input
                  class="m-0"
                  type="checkbox"
                  v-model="model.testifiesInCourt"
                  id="testifiesInCourt"
                  autocomplete="off"
                  hidden
                />
                <span v-lang="'victims.testifies_in_court'"></span>
              </label>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend v-lang="'victims.recruiting_mode'"></legend>
            <div class="fieldset-container">
              <div
                v-for="(list, key) in codesModel.recruitingModeCodes"
                class="input-checkbox"
                :key="key"
              >
                <label :for="list.pkCodeId">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.recruitingModes"
                    :id="list.pkCodeId"
                    :value="list.pkCodeId"
                    autocomplete="off"
                    hidden
                  />
                  <span v-html="list.name"></span>
                </label>
              </div>
              <div
                class="input m-0 custom-input"
                v-if="
                  !$_.isEmpty(model.recruitingModes) &&
                    model.recruitingModes.includes(1473)
                "
                :class="
                errors.first('victim_form.otherRecruitmentMode') !== undefined
                  ? 'input-error'
                  : ''
              "
              >
                <label for="otherRecruitmentMode">
                  <span v-lang="'victims.other_recruitment_mode'"></span>
                  <span class="required-field-star">&#x2605;</span>
                </label>
                <input
                  class="m-0"
                  v-model="model.otherRecruitmentMode"
                  type="text"
                  name="otherRecruitmentMode"
                  autocomplete="off"
                  v-validate="'required'"
                />
                <div>
                  <span class="error" v-html="errors.first('victim_form.otherRecruitmentMode')"></span>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend v-lang="'victims.offered_assistance_type'"></legend>
            <div class="fieldset-container">
              <div
                v-for="(list, key) in codesModel.offeredAssistanceKindCodes"
                class="input-checkbox"
                :key="key"
              >
                <label :for="list.pkCodeId">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.offeredAssistanceKinds"
                    autocomplete="off"
                    :value="list.pkCodeId"
                    :id="list.pkCodeId"
                    hidden
                  />
                  <span v-html="list.name"></span>
                </label>
              </div>
              <div
                v-if="
                  !$_.isEmpty(model.offeredAssistanceKinds) &&
                    model.offeredAssistanceKinds.includes(1490)
                "
                class="input m-0 custom-input"
                :class="
                errors.first('victim_form.otherTypeOfOfferedAssistance') !== undefined
                  ? 'input-error'
                  : ''
              "
              >
                <label for="otherTypeOfOfferedAssistance">
                  <span v-lang="'victims.other_type_of_offered_assistance'"></span>
                  <span class="required-field-star">&#x2605;</span>
                </label>
                <input
                  class="m-0"
                  v-model="model.otherTypeOfOfferedAssistance"
                  type="text"
                  name="otherTypeOfOfferedAssistance"
                  autocomplete="off"
                  v-validate="'required'"
                />
                <div>
                  <span
                    class="error"
                    v-html="errors.first('victim_form.otherTypeOfOfferedAssistance')"
                  ></span>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="fieldset" v-if="model.isMinor == true">
            <legend v-lang="'victims.child_support_kind'"></legend>
            <div class="fieldset-container">
              <div
                v-for="(list, key) in codesModel.childSupportKindCodes"
                class="input-checkbox"
                :key="key"
              >
                <label :for="list.pkCodeId">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.childSupportKinds"
                    :value="list.pkCodeId"
                    :id="list.pkCodeId"
                    autocomplete="off"
                    hidden
                  />
                  <span v-html="list.name"></span>
                </label>
              </div>
              <div
                class="input m-0 custom-input"
                v-if="
                  !$_.isEmpty(model.childSupportKinds) &&
                    model.childSupportKinds.indexOf(2813) > -1
                "
                :class="
                errors.first('victim_form.otherChildSupportType') !== undefined
                  ? 'input-error'
                  : ''
              "
              >
                <label for="otherChildSupportType">
                  <span v-lang="'victims.other_child_support_type'"></span>
                  <span class="required-field-star">&#x2605;</span>
                </label>
                <input
                  class="m-0"
                  v-model="model.otherChildSupportType"
                  type="text"
                  name="otherChildSupportType"
                  autocomplete="off"
                  v-validate="'required'"
                />
                <div>
                  <span class="error" v-html="errors.first('victim_form.otherChildSupportType')"></span>
                </div>
              </div>
            </div>
          </fieldset>
        </fieldset>

        <div class="submit">
          <Spinner
            :showSpinner="isSubmited"
            :buttonText="$lang('general.button_submit')"
            :buttonClass="'primary'"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import store from "./store";
import { ResponsiveDirective } from "vue-responsive-components";
import Loader2 from "@/components/General/Loader2";
import globalStore from "@/store";

Vue.use(VeeValidate);

export default Vue.extend({
  directives: {
    responsive: ResponsiveDirective
  },
  components: {
    Loader2
  },
  data() {
    return {
      resultsLoaded: false,
      model: {
        pkFormVictimsId: 0,
        number: null,
        fkInstitutionId: 0,
        fkInstitutionTypeCodeId: 0,
        fkGenderCodeId: 0,
        fkYearsCodeId: 0,
        fkCitizenshipCodeId: 0,
        isMinor: false,
        fkMinorYearsCodeId: 0,
        isMinorUnaccompanied: false,
        fkVictimTypeCodeId: 0,
        isHelpReceived: false,
        fkRecruitmentCountryCodeId: 0,
        fkDestinationCountryCodeId: 0,
        residencePermit: false,
        isHumanitarianStay: false,
        reflectionPeriod: false,
        internationalProtection: false,
        cooperation: false,
        testifiesInCourt: false,
        exploitationModes: [],
        recruitingModes: [],
        offeredAssistanceKinds: [],
        childSupportKinds: [],
        otherRecruitmentMode: null,
        otherTypeOfOfferedAssistance: null,
        otherChildSupportType: null,
        registrationDate: null,
        selectedCodes: []
      },
      disabledInstitution: false,
      id: this.$route.query.pkFormVictimsId,
      validation_messages: {
        custom: {
          number: {
            excluded: lang("validation.required"),
            required: lang("validation.required")
          },
          registrationDate: {
            required: lang("validation.required")
          },
          fkInstitutionId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkInstitutionTypeCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkGenderCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkYearsCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkCitizenshipCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkVictimTypeCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkMinorYearsCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          otherRecruitmentMode: {
            required: lang("validation.required")
          },
          otherTypeOfOfferedAssistance: {
            required: lang("validation.required")
          },
          otherChildSupportType: {
            required: lang("validation.required")
          }
        }
      },
      codesModel: {
        institutionCodes: [],
        institutionTypeCodes: [],
        genderCodes: [],
        yearCodes: [],
        countryCodes: [],
        minorYearCodes: [],
        victimTypeCodes: [],
        exploitationModeCodes: [],
        recruitingModeCodes: [],
        offeredAssistanceKindCodes: [],
        childSupportKindCodes: []
      },
      lang:
        globalStore.state.lang == "sr"
          ? "sr-cyrl"
          : globalStore.state.lang == "bs"
          ? "sr"
          : globalStore.state.lang == "hr"
          ? "hr"
          : "en",
      isSubmited: false,
      dateError: {
        field: "registrationDate",
        msg: this.$lang("validation.required"),
        rule: "required",
        scope: "victim_form"
      }
    };
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  created() {
    this.getCodesDropdown();
    this.initModel();
    this.getCodes();
    if (this.id != 0) {
      this.getByID();
    }
  },
  watch: {
    // "model.isMinor": function() {
    //   if (this.model.isMinor) {
    //     this.model.isMinorUnaccompanied = false;
    //     this.model.fkMinorYearsCodeId = 0;
    //   }
    // },
    "model.registrationDate": function() {
      if (this.model.registrationDate != null) {
        this.errors.remove("registrationDate", "victim_form");
      }
    },
    "model.offeredAssistanceKinds": function() {
      if (
        !this.$_.isEmpty(this.model.offeredAssistanceKinds) &&
        !this.model.offeredAssistanceKinds.includes(1490)
      ) {
        this.model.otherTypeOfOfferedAssistance = null;
      }
    },
    "model.childSupportKinds": function() {
      if (
        !this.$_.isEmpty(this.model.childSupportKinds) &&
        !this.model.childSupportKinds.includes(2813)
      ) {
        this.model.otherChildSupportType = null;
      }
    },
    "model.recruitingModes": function() {
      if (
        !this.$_.isEmpty(this.model.recruitingModes) &&
        !this.model.recruitingModes.includes(1473)
      ) {
        this.model.otherRecruitmentMode = null;
      }
    },
    "model.isMinor": function() {
      if (!this.model.isMinor) {
        this.model.isMinorUnaccompanied = null;
        this.model.fkMinorYearsCodeId = 0;
      }
    },
    "$route.query.pkFormVictimsId"() {
      if (this.$route.query.pkFormVictimsId == 0) {
        this.initModel();
        this.errors.clear();
        this.$validator.reset();
      } else {
        this.getByID();
      }
    }
  },
  methods: {
    initModel() {
      this.searchModel = {
        pkFormVictimsId: 0,
        number: null,
        fkInstitutionId: 0,
        fkInstitutionTypeCodeId: 0,
        fkGenderCodeId: 0,
        fkYearsCodeId: 0,
        fkCitizenshipCodeId: 0,
        isMinor: false,
        fkMinorYearsCodeId: 0,
        isMinorUnaccompanied: false,
        fkVictimTypeId: 0,
        isHelpReceived: false,
        fkRecruitmentCountryCodeId: 0,
        fkDestinationCountryCodeId: 0,
        residencePermit: false,
        isHumanitarianStay: false,
        reflectionPeriod: false,
        internationalProtection: false,
        cooperation: false,
        testifiesInCourt: false,
        otherRecruitmentMode: null,
        otherTypeOfOfferedAssistance: null,
        otherChildSupportType: null,
        registrationDate: null,
        selectedCodes: []
      };
    },
    validateRegistrationDate() {
      if (!this.model.registrationDate) {
        this.errors.add(this.dateError);
      }
    },
    getCodes() {
      this.resultsLoaded = false;
      store
        .dispatch("GET_CODES")
        .then(response => {
          this.codesModel.exploitationModeCodes =
            response.data.model.exploitationModeCodes;
          this.codesModel.recruitingModeCodes =
            response.data.model.recruitingModeCodes;
          this.codesModel.offeredAssistanceKindCodes =
            response.data.model.offeredAssistanceKindCodes;
          this.codesModel.childSupportKindCodes =
            response.data.model.childSupportKindCodes;

          // this.codesModel = response.data.model;

          this.resultsLoaded = true;
        })
        .catch(error => {});
    },
    getCodesDropdown() {
      // this.resultsLoaded = false;
      store
        .dispatch("GET_CODES_DROPDOWN")
        .then(response => {
          // this.codesModel = response.data.model;
          this.codesModel.institutionCodes =
            response.data.model.institutionCodes;
          this.codesModel.institutionTypeCodes =
            response.data.model.institutionTypeCodes;
          this.codesModel.genderCodes = response.data.model.genderCodes;
          this.codesModel.yearCodes = response.data.model.yearCodes;
          this.codesModel.countryCodes = response.data.model.countryCodes;
          this.codesModel.minorYearCodes = response.data.model.minorYearCodes;
          this.codesModel.victimTypeCodes = response.data.model.victimTypeCodes;
          if (response.data.model.institutionId != 5) {
            this.disabledInstitution = true;
            this.model.fkInstitutionId = response.data.model.institutionId;
          }
          // this.resultsLoaded = true;
        })
        .catch(error => {});
    },
    save() {
      var $this = this;
      this.validateRegistrationDate();
      this.$validator.validateAll("victim_form").then(valid => {
        if (valid && this.model.registrationDate != null && !this.isSubmited) {
          $this.isSubmited = true;
          store
            .dispatch("ADD_VICTIM", $this.model)
            .then(response => {
              $this.$success("victims.message_victim_successfully_added");
              $this.$router.push({
                name: "VictimsIndex",
                query: {
                  pageNum: 1
                }
              });
              $this.isSubmited = false;
            })
            .catch(error => {
              if (error.status == 400) $this.errorModel = error.data;
            });
        }
      });
    },
    update() {
      var $this = this;
      this.validateRegistrationDate();
      this.$validator.validateAll("victim_form").then(valid => {
        if (valid && this.model.registrationDate != null && !this.isSubmited) {
          $this.isSubmited = true;
          store
            .dispatch("UPDATE_VICTIM", this.model)
            .then(response => {
              this.$success("victims.victim_successfully_updated");
              this.$router.go(-1);
              $this.isSubmited = false;
            })
            .catch(error => {
              if (error.status == 400) $this.errorModel = error.data;
            });
        }
      });
    },
    getByID() {
      var $this = this;
      store
        .dispatch("GET_VICTIM_BY_ID", $this.$route.query.pkFormVictimsId)
        .then(response => {
          $this.model = response.data.model;
          $this.model.exploitationModes = $this.model.exploitationModes;
          $this.model.recruitingModes = $this.model.recruitingModes;
          $this.model.offeredAssistanceKinds =
            $this.model.offeredAssistanceKinds;
          $this.model.childSupportKinds = $this.model.childSupportKinds;
        })
        .catch(error => {});
    }
  }
});
</script>

<style lang="scss" scoped>
.form {
  .general-data {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .submit {
    display: flex !important;
  }
  .fieldset {
    padding-top: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 1/4;
    .fieldset-container {
      // display: grid;
      // grid-gap: 10px;
      // grid-template-columns: 1fr 1fr 1fr;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
    }
  }
  .fieldset-main-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.minor {
  display: flex;
  flex-direction: column;
}
.form.small {
  .general-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .submit {
    display: flex !important;
  }

  .fieldset-main-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
}

.custom-input {
  grid-column-start: 1;
}

div.input-checkbox {
  color: black;
  display: -webkit-inline-flex;
  margin: 0px 25px;
  margin-top: 5px;

  label {
    margin-bottom: 10px !important;
  }
}
fieldset {
  white-space: nowrap;
  div.input-checkbox[data-v-bbda633a] {
    margin: 0 !important;
  }
}
.form.large {
  fieldset {
    white-space: nowrap;
    div.input-checkbox[data-v-bbda633a] {
      margin: 0px 20px !important;
    }
  }
}
</style>
